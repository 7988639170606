import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

const date_time_picker = (selector, config) => {
  if(!config) {
    config = {};
  }
  config.parseDate = (datestr, format) => {
    const date = [dateFns.parse(datestr, format, new Date()),
                  dateFns.parse(datestr, 'MMddyyyy', new Date()),
                  dateFns.parseISO(datestr)].find(dateFns.isValid);
    return date || new Date(datestr);
  }
  config.formatDate = (date, format, _locale) => {
    return dateFns.format(date, format);
  }
  const errorHandlerFactory = (selector) => {
    return (e) => {
      console.log(e.message);
      var inputField = $j(selector).find('input.flatpickr')[0];
      inputField.dataset.valueOnError = inputField.value;
    }
  }
  const onValueUpdateFactory = (selector) => {
    return (e) => {
      var inputField = $j(selector).find('input.flatpickr')[0];
      if (inputField.dataset.valueOnError) {
        inputField.value = inputField.dataset.valueOnError;
        delete inputField.dataset.valueOnError;
      }
    }
  }

  if(!config.disableNow) {
    config.plugins = [
      ShortcutButtonsPlugin({
        button: { label: 'Now' },
        onClick: (idx, fp) => {
          const now = fp.formatDate(new Date(), fp.config.dateFormat);
          fp.setDate(new Date(), true);
        },
      })
    ];
  } else {
    config.plugins = [];
  }

  if (config.monthOnly) {
    config.plugins.push(
      new monthSelectPlugin({
        dateFormat: 'MMM yyyy',
        altFormat: 'MMM yyyy',
      })
    );
  }

  config.wrap = true;
  config.clickOpens = false;

  if(!config.hasOwnProperty('allowInput')) {
    config.allowInput = true;
  }

  if(config.enableTime == true) {
    config.dateFormat = "HH':'mm 'on' MMM d',' yyyy";
    config.time_24hr = true;
    config.minuteIncrement = 1;
  } else if (config.monthOnly) {
    config.dateFormat = 'MMM yyyy';
  } else if (config.batchFormat) {
    config.dateFormat = "MMMM d',' yyyy";
  } else {
    config.dateFormat = "MMM d',' yyyy";
  }

  const inputField = $j(selector).find('input.flatpickr');
  config.errorHandler = errorHandlerFactory(selector);
  config.onValueUpdate = onValueUpdateFactory(selector);
  if (config.dateFormat) {
    config.ariaDateFormat = config.dateFormat;
  }

  const picker = flatpickr(selector, config);

  const setFieldVal = (field, date) => {
    picker.setDate(date);
  };

  return picker;
};

export default date_time_picker;
